<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionAddRisk">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionAddRisk">
      <b-col>
        <b-container>
          <b-row class="mb-4" v-if="firststep">
            <b-col>
              <h4>Adding new market abuse risk</h4>
              <div class="mb-2"><strong>Name</strong></div>
                <b-form-input   id="titleInput"
                                v-model="topic.name"
                                placeholder="Risk name"
                                required
                                class="mb-2">
                </b-form-input>
              <div class="mb-2"><strong>Definition</strong></div>
                <b-form-textarea id="detailsInput"
                                v-model="topic.comments"
                                placeholder="Risk definition"
                                required
                                rows=4
                                class="mb-2">
                </b-form-textarea>
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="preview">Preview</b-button>
                <b-button variant="outline-primary" size="sm" :to="{ name: 'Risks' }">Cancel</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="previewstep">
            <b-col>
              <h4>{{topic.name}}</h4>
              <div v-html="rendered.comments"></div>
              <span v-if="!saving">
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
                <b-button variant="outline-primary" size="sm" v-on:click="edit">Edit</b-button>
              </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="finalstep">
            <b-col>
              <h4>Created: {{topic.name}}</h4>
              <div>{{topic.comments}}</div>
              <b-button variant="primary" size="lg" :to="{ name: 'RiskEdit', params: { id: topic.id }}">Continue to edit details</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MarkdownIt from 'markdown-it'
import ac from '../libs/accesscontrol'

export default {
  name: 'TopicAdd',
  beforeDestroy () {},
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.topic.name && this.topic.comments
    }
  },
  created: function () {
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
  },
  data () {
    return {
      firststep: true,
      finalstep: false,
      permissionAddRisk: false,
      previewstep: false,
      saving: false,
      rendered: {
        comments: null
      },
      topic: {
        name: null,
        comments: null
      }
    }
  },
  methods: {
    edit: function () {
      this.firststep = true
      this.previewstep = false
      this.finalstep = false
    },
    preview: function () {
      this.firststep = false
      this.finalstep = false
      this.md = new MarkdownIt()
      this.md.set({ linkify: true })
      this.rendered.comments = this.md.render(this.topic.comments)
      this.previewstep = true
    },
    save: async function () {
      this.$logger.debug(`save start`)
      this.previewstep = false
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/risk`
        let params = {
          body: {
            topic: this.topic
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.topic = response
        this.finalstep = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
